.ng-select.dropdown-filter {
    .ng-select-container {
        max-height: 36px;
        min-width: 80px;
        border: 0px;

        .ng-input {
            display: none;
        }

        .ng-value-container {
            padding-left: 8px;
            .ng-value {
                background-color: transparent;

                .ng-value-label {
                    color: #3c70d5;
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            .ng-placeholder {
                line-height: 14px;
                font-size: 12px;
                color: #7d7d7d;
            }
        }
    }
}
.dropdown-filter,
.multiselect-filter {
    &.ng-dropdown-panel.ng-select-bottom {
        min-width: 220px;
        max-width: 330px;
        width: auto !important;
        opacity: 1;
        margin-top: 7px;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 0;
        box-shadow: 0 -2px 0 0 #2a96ff;
        border: 1px solid #ccc;
        .ng-arrow-wrapper {
            padding-left: 4px;
        }

        .select-group-prepend {
            display: flex;
            align-items: center;

            input {
                width: 80%;
                height: 100%;
                border: 0px;
                color: #b7bbc0;
                font-size: 12px;
            }
        }

        .select-group-text {
            box-sizing: border-box;

            i {
                color: #b7bbc0;
            }
        }

        .ng-dropdown-header {
            border-bottom: 0px;
        }

        @media only screen and (max-width: 1400px) {
            max-width: 300px;
        }
        @media only screen and (max-width: 1300px) {
            max-width: 250px;
        }
        margin-top: 4px;
        .ng-option {
            height: 36px;
            .ng-option-label {
                color: rgb(74, 74, 74);
                font-size: 12px;
                font-weight: normal;
                min-height: 20px;
                letter-spacing: 0px;
                line-height: 14px;
            }
        }

        .ng-dropdown-panel-items {
            .ng-option {
                height: 36px;
                white-space: pre-wrap;
                color: #4a4a4a;
                font-size: 14px;
                letter-spacing: 0.1px;
                line-height: 14px;
                box-shadow: inset 0 -1px 0 0 #e5e8ec;
                &.ng-option-marked {
                    background-color: #f2f2f2;
                }
                &.ng-option-disabled {
                    color: #7d7d7d !important;
                    font-size: 12px;
                }
            }
        }
    }
}

.multiselect-filter .input-group {
    border: 1px solid #e5e8ec;
    border-radius: 4px;
    margin-top: 8px;
    .input-group-text,
    .form-control {
        opacity: 0.8;
        border-radius: 4px;
        background-color: white;
        border: none;
        color: #7d7d7d;
        font-size: 13px;
        height: initial;
    }
    .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .input-group-prepend {
        height: initial;
    }
}

.ng-select.multiselect-filter {
    .ng-select-container {
        max-height: 36px;
        min-width: 80px;
        border: 0px;

        .ng-input {
            display: none;
        }

        .ng-value-container {
            padding-left: 8px;
            padding-top: 0px;
            .ng-value {
                background-color: transparent;

                .ng-value-label {
                    color: #3c70d5;
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            .ng-placeholder {
                line-height: 24px;
                font-size: 12px;
                color: #7d7d7d;
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .dropdown-filter,
    .multiselect-filter {
        .ng-arrow-wrapper {
            display: inline-block;
            line-height: 36px;
        }
    }
}

.custom-pagination ::ng-deep .ngx-pagination {
    .current {
        background-color: yellow;
    }
}
