.brand-zetwerk {
  content: '';
  height: 32px;
  width: 32px;
  background: url('/assets/zetui/svg/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  align-items: center;
  &::after {
    content: 'ZETWERK';
    font-size: 16px;
    margin-left: 3rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: white;
  }
}
