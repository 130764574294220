/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr';
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'projects/zetui/assets/zetui/scss/zetui.scss';
@import 'material-icons/iconfont/material-icons.css';

body {
  margin: 0px;
  padding: 0px;
}

// todo remove
.sidemenu {
  z-index: 999;
}

.sidemenu-is-close {
  & .zet-btn-close {
    display: none;
  }
  & .welcome {
    display: none;
  }
  & .btn-logout {
    display: none;
  }
}

.popover-container {
  z-index: 1200 !important;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.3);
  background-color: #fff !important;
}

.cdk-overlay-pane {
  z-index: 1200 !important;
}

.container__arrow {
  background-color: #fff !important;
}

.container__arrow--tc {
  border-left: 1px solid #fff !important;
  border-top: 1px solid #fff !important;
}

.sidemenu .sidemenu--nav {
  .sidemenu--item:nth-last-child(2) {
    margin-top: 50px;
    border-top: 1px solid gray;
  }
}

.sidemenu--content-is-expanded {
  iframe {
    width: 900px;
  }
  .full-frame iframe {
    width: calc(100vw - 60px);
  }
}

.sidemenu--content-is-collapsed {
  iframe {
    width: 700px;
  }

  .full-frame iframe {
    width: calc(100vw - 240px);
  }
}

// TODO Make feature module for this
// Feature dashboard css
.container {
  width: 100%;
  padding: 24px 50px;
}

.feature-defination {
  display: block;
  font-size: 14px;
  color: #393d41;
  margin-bottom: 16px;
}

.feature-content {
  margin: 16px 0px;
}

.feature-example-viewer {
  width: 100%;
  border: 1px solid #333;
  margin: 16px 0px;
  padding: 16px;

  & > h4 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .material-icons {
    cursor: pointer;
  }
}

// File upload exposed state
.upload--select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 24px;
  }
}

.tab-pane {
  padding-top: 20px;
}

.tab-content {
  width: 100%;
}

// zet-list class
.card-row {
  width: 100%;

  & input {
    margin-top: 10px;
  }
}
.card-item {
  width: 100%;
}

// spy scroll
.spyscroll-link-isactive {
  & .spyscroll-title {
    color: #0075e3;
  }
}
