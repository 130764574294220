.nav {
  background-color: white;
}

.nav-item {
  margin-bottom: -1px;
  .nav-link {
    border: none;
    padding: 8px;
    cursor: pointer;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.nav-tabs {
  gap: 7px;
  border-bottom: 1px solid #e5e8ec;
  font-size: 14px;
  .nav-item {
    .nav-link {
      transition: border-color 300ms ease;
      color: rgb(125, 125, 125);

      &.active {
        color: #0075d0;
        border-bottom: 4px solid #0075d0;
        transition: border-color 400ms ease;
      }
      &:hover {
        color: #0079c9;
      }
    }
  }
}

.nav-tabs-vertical {
  display: flex;

  & > .nav-tabs {
    display: flex;
    flex-direction: column;
    gap: 0;

    .nav-item .nav-link {
      padding: 20px 16px;
      color: rgb(125, 125, 125);
      width: 360px;

      &.active {
        border: 0;
        background: rgb(230, 241, 252);
        border-right: 4px solid #0075d0;

        color: rgb(0, 117, 208);
        font-weight: 600;
      }
    }

    .nav-item + .nav-item {
      border: 1px solid rgb(216, 216, 216);
    }
    .nav-item:first-child {
      border: 1px solid rgb(216, 216, 216);
    }
  }
}
