.spyscroll {
  position: relative;
}

.spyscroll-link {
  &--title {
    font-size: 14px;
    color: #4a4a4a;
  }

  &--subtext {
    font-size: 12px;
    color: #9b9b9b;
  }

  &:hover {
    cursor: pointer;
  }
}
