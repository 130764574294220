.widget {
  font-family: 'Roboto';
}

.modal-full-width {
  margin: 0;
  width: 100vw;
  height: 100vh;

  .modal-content {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 576px) {
  .modal-full-width {
    max-width: 100vw;
  }
}

@keyframes slide {
  100% {
    transform: translateX(0);
  }
}

.modal.show .widget-slide-in.modal-dialog {
  transform: translateX(100%);
  animation: slide 0.5s forwards;
}
