@import '../variable';

.stepper {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.stepper--arrow {
  height: 24px;
  width: 24px;

  color: $black-light;

  margin-left: auto;

  transition: transform 200ms linear;
}

.stepper--header {
  display: flex;
  align-items: center;
  padding: 24px;
  &:hover {
    cursor: pointer;
  }
}

.stepper--icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  background: $blue;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  &-error {
    background-color: $red;
  }
  &-visited {
    background-color: $green;
  }
  &-current {
    background-color: $blue;
  }
  &-disabled {
    border: 2px solid $grey-lighter;
    background: $white;
    color: $grey-lighter;
  }
  &-unvisited {
    border: 2px solid $grey-lighter;
    background-color: $white;
    color: $black;
  }
}

.stepper--icon--content {
  font-size: 12px;
}

.stepper--title {
  font-size: 14px;
  font-weight: bold;
}

.stepper--body {
  margin-left: 35px;
  position: relative;
}

.stepper--content {
  overflow: auto;
  padding: 0px 24px 24px 22px;
}

.stepper--vertical-line {
  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: -16px;
    bottom: -16px;
    border-left: 2px solid $blue;
    z-index: 1;
  }
  &-error::before {
    border-color: $red;
  }
  &-visited::before {
    border-color: $green;
  }
  &-current::before {
    border-color: $blue;
  }
  &-unvisited::before {
    border-color: $grey-lighter;
  }
}

// state
.stepper-is-active {
  background-color: $blue-light;
}

.accordion-is-open {
  & .stepper--arrow {
    transform: rotate(180deg);
  }
}

.accordion-is-close {
  & .stepper--arrow {
    transform: rotate(0deg);
  }

  .stepper--content {
    display: none;
  }
}
