.file-card {
  display: flex;
  align-items: center;
  padding: 12px;
  min-height: 72px;
  &:not(:last-of-type) {
    border-bottom: 1px solid #e7e7e7;
  }
  &:hover {
    background-color: rgba(223, 227, 232, 0.3);
    & .file-card--progress > .success,
    .error,
    .file-card--progress-circular {
      display: none;
    }
    & .file-card--progress ~ .file-card--actions {
      display: flex;
    }
  }
  & h6 {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &--actions {
    margin-left: auto;
    display: none;
    button {
      background-color: transparent;
      padding: 0px;
      border: 0px;
      outline: none;
    }
    & .material-icons {
      cursor: pointer;
      color: #6f6f6f;
      margin: 0px 6px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  &--icon {
    height: 24px;
    width: 24px;
  }
  &--info {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    margin: 0px 8px;
  }
  &--status {
    font-size: 10px;
    color: rgb(125, 125, 125);
  }
  &--progress {
    margin-left: auto;
    & .success {
      color: #368f36;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
    }
    & .error {
      color: #c90000;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
    }
  }
}

// TODO  remove this from here
// duplicate
.upload--section .upload--select-btn {
  margin: 0px 6px;

  &.upload--select-btn--disabled {
    cursor: not-allowed;
  }

  & + input {
    display: none;
  }
}

.zbtn-primary {
  font-family: 'Roboto';
  color: #fff;
  background-color: #0075e3;

  font-weight: 500;
  font-size: 12px;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.103147);
  padding: 8px 12px;

  line-height: 16px;
  cursor: pointer;
}

.zbtn-secondary {
  font-family: 'Roboto';
  color: #4a4a4a;
  background-color: #fff;
  font-weight: 500;
  font-size: 12px;
  outline: none;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  padding: 8px 12px;

  line-height: 16px;
  cursor: pointer;
}
