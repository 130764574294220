.widget-form {
  display: grid;
  gap: 20px;

  &--group {
    flex-direction: column;
    display: flex;
    position: relative;
    min-width: 0px;

    &-inline {
      flex-direction: row;
      align-items: center;
    }

    & input[type='checkbox'] + label {
      padding-left: 24px;
      &:hover {
        &::before {
          border: 1px solid #0075e3;
        }
      }
    }

    & input[type='checkbox'] + label::before {
      content: '';
      box-sizing: border-box;

      position: absolute;
      width: 16px;
      height: 16px;
      left: 0px;
      top: calc(50% - 16px / 2);

      background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
      border: 1px solid #c4cdd5;
      box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
      border-radius: 3px;
    }

    & input[type='checkbox']:checked + label::before {
      background-color: #0075e3;
      border: 1px solid #0075e3;
      background-image: url('/assets/zetui/svg/timeline/tick.svg');
      background-position-x: 2px;
      background-position-y: 3px;
      background-size: 10px;
      background-repeat: no-repeat;
      box-shadow: 0px 1px 1px rgba(97, 121, 133, 0.0528), 0px 1px 1px rgba(64, 82, 88, 0.032);
    }
  }

  &--row {
    display: flex;
    flex-wrap: wrap;
  }

  &--label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #777777;
    margin-bottom: 4px;
    position: relative;
  }

  &--textarea {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    padding: 6px 8px;
    font-size: 14px;
    color: #4a4a4a;
  }

  &--control {
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    padding: 6px 8px;
    font-size: 14px;
    color: #4a4a4a;

    &::placeholder {
      font-size: 14px;
      line-height: 20px;
      color: #9b9b9b;
      font-weight: 400;
    }

    &:focus {
      border-color: #0075e3;
      background-color: #f2f8fe;
      outline: none;
    }

    &:disabled {
      background-color: #f6f6f6;
      border: 1px solid #d2d2d2;
      cursor: not-allowed;
    }

    &:read-only {
      background-color: #f6f6f6;
      border: 1px solid #d2d2d2;
      cursor: not-allowed;
      font-weight: 400;
    }

    &-error {
      background-color: #fdf6f4;
      border: 1px solid #cc4b24;
      box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);

      &:read-only {
        background-color: #fdf6f4;
        border: 1px solid #cc4b24;
      }

      &:focus {
        outline: none;
        border-color: #cc4b24;
        background-color: #fdf6f4;
      }
    }
  }

  &--checkbox {
    display: none;
  }

  &--input {
    height: 32px;
  }
}

.required {
  color: #cc4b24;
}

.span-2 {
  grid-column: span 2;
}

.span-full {
  grid-column: 1 / -1;
}

.g-22 {
  grid-template-areas: '11 12' '21 22';
}

.g-11 {
  grid-template-areas: '11';
}
