@import '../variable';

.backdrop {
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;

  visibility: hidden;

  z-index: $zindex-sidemenu-backdrop;
}

.backdrop-is-active {
  visibility: visible;
  z-index: $zindex-sidemenu-backdrop-negative;

  background-color: $black;
  opacity: 0.5;

  transition: visibility, opacity, background-color 300ms linear;
}

.sidemenu {
  height: 100%;
  width: 250px;
  box-sizing: border-box;
  background: linear-gradient(-45deg, rgb(37, 42, 51) 0%, rgb(56, 63, 74) 100%);
  border-radius: 0px;
  box-shadow: inset -2px 0px 4px 0px rgba(0, 0, 0, 0.2);

  position: fixed;
  top: 0px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  z-index: $zindex-sidemenu;
  overflow: hidden;

  transition: width 300ms ease-in;
}

.sidemenu--content {
  transition: margin 300ms ease-in;
}

.sidemenu--header {
  padding: 12px;
}

.sidemenu--info {
  min-height: 120px;
}

.sidemenu--footer {
  margin-top: auto;
  padding: 12px 16px;
}

.sidemenu--nav > ul {
  list-style: none;
  padding: 0px;
}

.sidemenu--item {
  padding: 8px 16px;
  margin: 0px;
  display: flex;
  align-items: center;

  color: $grey-light;
}

.sidemenu--item:hover {
  color: $white;
  cursor: pointer;
}

.sidemenu--item-content {
  display: flex;
  align-items: center;

  & p {
    margin: 0px 0px 0px 8px;
    padding: 0px;
    font-size: 12px;

    opacity: 1;
    transition: 350ms opacity ease-in;
  }

  .title-is-hidden {
    opacity: 0;
    transition: 100ms opacity ease;
  }
}

//state
.sidemenu--content-is-collapsed {
  &.sidemenu--content {
    &-push {
      margin-left: 250px;
      margin-right: -250px;
    }
    &-side {
      margin-left: 250px;
    }
    &-over {
      margin-left: 60px;
    }
  }
}

.sidemenu--content-is-expanded {
  &.sidemenu--content {
    &-push {
      margin-left: 60px;
      margin-right: 0px;
      transition-timing-function: ease-out;
    }
    &-side {
      margin-left: 60px;
      transition-timing-function: ease-out;
    }
    &-over {
      margin-left: 60px;
    }
  }
}

.sidemenu-is-close {
  width: 60px;
  transition: width 300ms ease-out;
}

.item-is-selected {
  background-color: $grey-dark;
  border-left: 4px solid $white;
  color: $white;
  padding: 8px 12px;
}
