@import '../variable.scss';

.file-viewer {
  width: 100%;
  height: 100%;

  position: relative;

  &:hover .file-viewer-control {
    visibility: visible;
  }
}

.file-viewer-control {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border-radius: 4px;
  bottom: 0px;
  z-index: 3;

  display: flex;
  justify-content: space-between;
  align-items: center;

  visibility: hidden;

  background-color: #4a4a4a;
  color: $white;
  font-size: 12px;

  width: fit-content;
  padding: 12px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & > * {
    margin: 0px 8px;
  }
}

.pdf-viewer {
  width: 100%;
  height: 100%;

  &:hover .file-viewer-control {
    visibility: visible;
  }
}

.image-viewer {
  width: 100%;
  height: 100%;

  &:hover .file-viewer-control {
    visibility: visible;
  }
}

/**
  Styling ng select inside file viewer component
  TODO We will replace it with zet-dropdown
*/
.ng-select.file-viewer--dropdown {
  min-width: 180px;
  max-width: 200px;
  background-color: transparent;
  border: 0px;
  .ng-select-container {
    background-color: transparent;
    color: $white;
    border: 0px;
    outline: none;
  }
}
