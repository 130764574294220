@import '../variable.scss';

.timeline {
  display: flex;
  list-style: none;
  padding: 0px;
}

.timeline--horizontal {
  & > .timeline--step {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 20px;
    & .timeline--body {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
    }
    &:not(:last-of-type) .timeline--body {
      &::before {
        content: '';
        position: absolute;
        left: 0px;
        right: 0px;
        transform: translate(16px, -0.5 * (17px - 3px) - 3px - 12px);
        border-top: 3px solid $blue;
      }
    }
  }
}

.timeline--vertical {
  flex-direction: column;
  & > .timeline--step {
    display: flex;
    flex-direction: row;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 20px;
    &:not(:last-of-type) .timeline--body {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 100% + 2.5 * 3;
        transform: translate(-0.5 * (17px - 3px) - 3px - 12px, 16px);
        top: 0px;
        border-left: 3px solid $blue;
      }
    }
    & .timeline--body {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
    }
  }
}

.timeline--icon {
  background: $white;
  border-radius: 50%;
  border: 3px solid $blue;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
}

.timeline--horizontal > .timeline--step.timeline--step,
.timeline--vertical > .timeline--step.timeline--step {
  &-completed {
    & .timeline--body::before {
      border-color: $green;
    }
    & > .timeline--icon {
      border-color: $green;
      background: $green;
      /* FIXME Replace with material */
      background-image: url('/assets/zetui/svg/timeline/tick.svg');
    }
  }
  &-current {
    & .timeline--body::before {
      border-color: $blue;
    }
    & > .timeline--icon {
      border-color: $blue;
    }
  }
  &-error {
    & .timeline--body::before {
      border-color: $red;
    }
    & > .timeline--icon {
      border-color: $red;
      background-color: $red;
      background-image: url('/assets/zetui/svg/timeline/exclamation-mark.svg');
    }
  }
  &-loading {
    & > .timeline--icon {
      border-color: #bfddf8;
      border-top-color: $blue;
      border-right-color: $blue;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
  }
  &-pending {
    & > .timeline--icon {
      border-color: $grey-lighter;
      // background: $grey-lighter;
    }
  }
  &-unvisited {
    & .timeline--body::before {
      border-color: $grey-lighter;
    }
    & > .timeline--icon {
      border-color: $grey-lighter;
    }
  }
}

/*
  border-style sets the line style for all four sides of an element's border that's why have
  to define it individually
  TODO write mixin for this
*/
.timeline--vertical > .timeline--step.timeline--step {
  &-loading {
    & .timeline--body::before {
      border-left: 3px dashed $blue;
    }
  }
  &-pending {
    & .timeline--body::before {
      border-left: 3px dashed $grey-lighter;
    }
  }
  &-ongoing {
    & .timeline--body::before {
      border-left: 3px dashed $blue;
    }
  }
  &-partially {
    & .timeline--body::before {
      border-left: 3px dashed $grey-lighter;
    }
  }
}

.timeline--horizontal > .timeline--step.timeline--step {
  &-loading {
    & .timeline--body::before {
      border-top: 3px dashed $blue;
    }
  }
  &-pending {
    & .timeline--body::before {
      border-top: 3px dashed $grey-lighter;
    }
  }
  &-ongoing {
    & .timeline--body::before {
      border-top: 3px dashed $blue;
    }
  }
  &-partially {
    & .timeline--body::before {
      border-top: 3px dashed $grey-lighter;
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
