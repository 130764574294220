$zindex-sidemenu: 1030;
$zindex-sidemenu-backdrop: 1020;
$zindex-sidemenu-backdrop-negative: 1020;

$blue: #0075e3;
$blue-light: #edf5fd;
$red: #cc4b24;
$green: #368f36;
$white: #fff;

$black: #333;
$black-light: #6f6f6f;
$white: #fff;
$grey-light: #818896;
$grey-lighter: #e3e3e3;
$grey-dark: #454a52;

$primary: $blue;
